/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { useVodaVisualizations } from '@graphql'

import * as styles from './Root.module.scss'

import { AppContext } from '@components/App'

import {
  Header,
  Goal,
  Main,
  Schema,
  Benefit,
  OldSolutions,
  Technology,
  VideoPresentation,
  Visualization,
} from '@components/gorod'

import { Author, News, Invest, Patents, Streams, StockCharts, Committee, Future, Footer } from '@components/sections'

import { useGorodNews, useGorodPatents } from '../../../share/graphql'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { streams } from '../../../share/consts'
import { Modal } from '@components/App/Modal'
import { EmbedVideo, Typography } from '@UIKit'
import { Content, getContent } from '@utils'
import { getNewsData } from '../../../../../api/news'
import threads from '../../../../assets/images/weter/socialIcon/Threads.svg'
import twitter from '../../../../assets/images/weter/socialIcon/Twitter.svg'
import telegram from '../../../../assets/images/weter/socialIcon/Telegram.svg'
import instagram from '../../../../assets/images/weter/socialIcon/Instagram.svg'
import facebook from '../../../../assets/images/weter/socialIcon/Facebook.svg'
import linkedin from '../../../../assets/images/weter/socialIcon/LinkedIn.svg'
import youtube from '../../../../assets/images/weter/socialIcon/YouTube.svg'
import { getApi } from '../../../../../api/_utils'

const linksData = [
  {
    icon: threads,
    content: 'Threads',
    url: 'https://www.threads.net/@weter_world',
  },
  {
    icon: twitter,
    content: 'Twitter',
    url: 'https://twitter.com/denistiaglin',
  },
  {
    icon: telegram,
    content: 'Telegram',
    url: 'https://t.me/WeterProjectBot',
  },
  {
    icon: instagram,
    content: 'Instagram',
    url: 'https://www.instagram.com/weter_world/',
  },
  {
    icon: facebook,
    content: 'Facebook',
    url: 'https://www.facebook.com/groups/tiaglinwetergorod/',
  },
  {
    icon: linkedin,
    content: 'Linkedin',
    url: 'https://www.linkedin.com/in/denis-tiaglin-03484358/',
  },
  {
    icon: youtube,
    content: 'YouTube',
    url: 'https://www.youtube.com/c/DENISTIAGLIN',
  },
]

export const Root = () => {
  const [presentationModal, setPresentationModal] = useState(false)
  const [selectedNews, setSelectedNews] = useState(0)
  const [newsModal, setNewsModal] = useState(false)
  const [selectedDescription, setSelectedDescription] = useState<Content[]>([])
  const [investorCount, setInvestorCount] = useState(2174)

  const news = useGorodNews()
  const patents = useGorodPatents()

  useEffect(() => {
    const getDescription = async () => {
      const description: Content[] = await getNewsData(news[selectedNews].id).then(({ data }) => data.content)
      setSelectedDescription(description)
    }

    getDescription()
  }, [selectedNews])

  const videos = {
    // ru: 'https://youtu.be/',
    en: '7S1_47Qfan8',
  }

  const openPresentationModal = () => {
    setPresentationModal(true)
  }

  const openNewsModal = () => {
    setNewsModal(true)
  }
  const { langCode } = useContext(AppContext)

  useEffect(() => {
    const getInvestorCount = async () => {
      const count = await fetch(getApi('projects/projects/grd/investorcount'))
        .then((data) => data.json())
        .then(({ data }) => data.count)

      setInvestorCount(count)
    }

    getInvestorCount()
  }, [])

  return (
    <>
      <Header />
      <main className={styles.body}>
        <Main
          selectedNews={selectedNews}
          openNewsModal={openNewsModal}
          openPresentationModal={openPresentationModal}
          newsMain={news}
        />
        <Schema />
        <Benefit />
        <Technology />
        <OldSolutions />
        <VideoPresentation openPresentationModal={openPresentationModal} />
        <Visualization useVisualizationHook={useVodaVisualizations} />
        <Author />
        <Patents patents={patents} gorod />
        <Goal />
        <News setSelectedNews={setSelectedNews} openNewsModal={openNewsModal} newsHook={news} />
        <Streams
          streams={[
            { ...streams.a, technical: false },
            { ...streams.b, technical: true },
            { ...streams.c, technical: true },
          ]}
        />
        <StockCharts name={'G.O.R.O.D'} title={'G.O.R.O.D Project'} />
        <Invest countNumber={investorCount} project={'gorod'} />
        <Committee />
        <Future theme={'light'} />
      </main>
      <Footer project={'gorod'} />

      <Modal className={styles.video} isOpen={presentationModal} onRequestClose={() => setPresentationModal(false)}>
        <EmbedVideo youtubeID={videos.en} />
      </Modal>
      <Modal className={styles.news} isOpen={newsModal} onRequestClose={() => setNewsModal(false)}>
        {news[selectedNews].mainImage ? (
          <GatsbyImage image={news[selectedNews].mainImage} alt="" />
        ) : (
          <StaticImage
            src="../../../../assets/images/weter/newsPoster.jpg"
            alt="GOROD LES LOGO"
            placeholder="blurred"
            aspectRatio={1.77} // 16 / 9
          />
        )}
        <div className={styles.text}>
          <Typography
            className={styles.title}
            size={32}
            dangerouslySetInnerHTML={{ __html: getContent(news[selectedNews].title) }}
          />
          <Typography
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: getContent(selectedDescription).replaceAll(/<img[^>]*>|<hr[^>]*>[\s\S]*/gm, ''),
            }}
          />
        </div>
        <div className={styles.links}>
          {linksData.map((link) => (
            <a href={link.url} key={link.content} className={styles.item}>
              <img className={styles.icon} src={link.icon} alt={link.content} />
              <Typography disableLocalize className={styles.social} size={16} weight={500}>
                {link.content}
              </Typography>
            </a>
          ))}
        </div>
      </Modal>
    </>
  )
}
